import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import "./styles.scss";
import MobileNavbar from "../mobile-navbar";
import { AiOutlineMenu } from "react-icons/ai";
import SVG from "react-inlinesvg";

// Assets
import AboutNavImg from "../../assets/about-nav.svg";
import MusicNavImg from "../../assets/music-nav.svg";
import VideoNavImg from "../../assets/video-nav.svg";
import ContactNavImg from "../../assets/contact-nav.svg";
import Animation from "../../assets/animation.svg";

const Navbar: React.FC = () => {
  const [isSticky, setSticky] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    { title: "About", link: "about", image: AboutNavImg },
    { title: "Music", link: "music", image: MusicNavImg },
    { title: "Video", link: "video", image: VideoNavImg },
    { title: "Contact", link: "contact", image: ContactNavImg },
  ];

  return (
    <div
      className={`px-4 lg:px-0 bg-[#00000087] lg:fixed z-50 top-0 right-0 left-0 h-[121px] flex items-center justify-between lg:justify-center ${
        isSticky && ""
      }`}
    >
      <div className="relative flex justify-between w-full h-full max-w-6xl mx-auto">
        <div className="flex items-center justify-between w-full max-w-6xl mx-auto">
          {/* Navbar Brand */}
          <div className="relative">
            <Link to="/">
              <StaticImage
                src="../../assets/logo.webp"
                alt="IvyWild"
                className="w-52 lg:w-11/12"
                loading="eager"
              />
            </Link>
          </div>

          {/* Nav Items */}
          <div className="hidden lg:block">
            <ul className="flex items-center justify-start">
              {navItems.map((item: string, index: number) => (
                <li key={index}>
                  <Link
                    to={`#${item.link}`.toLowerCase()}
                    className="text-white text-2xl uppercase font-bold hover:text-[#ED1E79]
                      transition-all ease-out duration-[0.2s] "
                  >
                    <SVG src={item.image} />
                    {/* {item.title} */}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {/* Mobile-Nav Menu Button*/}
      <div onClick={() => setMobile(true)} className="block lg:hidden">
        <AiOutlineMenu className="text-white cursor-pointer" fontSize={30} />
      </div>
      {/* Mobile-Nav Menu */}
      {isMobile && (
        <div className="block lg:hidden">
          <MobileNavbar setMobile={setMobile} navbarData={navItems} />
        </div>
      )}
    </div>
  );
};

export default Navbar;
