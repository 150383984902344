import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SVG from "react-inlinesvg";

// Assets
import OverlayImg from "../../assets/poster-overlay.svg";
import DownloadArrowImg from "../../assets/download-arrow.svg";

const NewRelease: React.FC = ({
  data: { releasePosterImage, releaseVideo },
}) => {
  const poster = getImage(releasePosterImage);

  return (
    <div className="max-w-6xl mx-auto pt-16 md:pt-24 lg:pt-[168px] new-release">
      <div className="w-full max-w-screen-xl px-6 mx-auto xl:px-0">
        <div className="relative w-full h-full mx-auto md:w-1/2">
          <GatsbyImage
            image={poster}
            alt={poster.title}
            className="object-contain w-full h-full poster"
          />
          <SVG src={OverlayImg} className="hidden md:block poster-overlay" />
          <div className="flex items-center justify-center mt-4">
            <a
              // href="/video.mp4"
              href={releaseVideo}
              target="__blank"
              download="spinning turntable video"
              className="action-btn"
            >
              <span className="flex">
                <i className="pr-2 md:pr-4">
                  <SVG src={DownloadArrowImg} />
                </i>
                <button className="text-sm text-white uppercase md:text-xl">
                  Download interactive turntable video
                </button>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRelease;
