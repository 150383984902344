import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { BsFacebook, BsYoutube } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { useStaticQuery, graphql } from "gatsby";

import SVG from "react-inlinesvg";

// Assets
import FacebookIcon from "../../assets/fb.svg";
import InstagramIcon from "../../assets/instagram.svg";
import TiktokIcon from "../../assets/tiktok.svg";
import YoutubeIcon from "../../assets/youtube.svg";

const Footer: React.FC = ({
  data: { socialFacebook, socialInstagram, socialTikTok, socialYoutube },
}) => {
  const date = new Date().getFullYear();
  const navItems = [
    { title: "About", link: "about" },
    { title: "Music", link: "music" },
    { title: "Video", link: "video" },
    { title: "Contact", link: "contact" },
  ];

  const openSocialLink = (link: string) => {
    if (typeof window !== "undefined") {
      window.open(link, "_newtab");
    }
  };

  return (
    <div className="max-w-screen-xl w-full mx-auto mt-[50px] px-10 md:px-24">
      <div className="flex flex-col items-center justify-between lg:flex-row">
        {/* Logo */}
        <div>
          <Link to="/">
            <StaticImage src="../../assets/logo-white.webp" alt="IvyWild" />
          </Link>
        </div>
        {/* Links */}
        <div className="flex flex-col items-center justify-between my-5 lg:my-0 md:flex-row">
          {navItems.map((item: string, id: number) => (
            <div
              className="flex items-center justify-between my-3 text-gray-500 md:my-0"
              key={id}
            >
              <hr className="hidden rotate-90 w-7 md:block" />
              <Link
                to={`#${item.link}`}
                className="text-lg md:text-base font-semibold hover:text-[#ED1E79] uppercase tracking-wider md:tracking-normal"
              >
                {item.title}
              </Link>
            </div>
          ))}
          <hr className="hidden rotate-90 w-7 md:block" />
        </div>
        {/* Social icons */}
        <div className="flex items-center justify-between">
          <div
            onClick={() => openSocialLink(socialFacebook)}
            className="hover:cursor-pointer"
          >
            <SVG src={FacebookIcon} width={25} className="mr-5" />
          </div>
          <div
            onClick={() => openSocialLink(socialInstagram)}
            className="hover:cursor-pointer"
          >
            <SVG src={InstagramIcon} width={30} className="mr-5" />
          </div>
          <div
            onClick={() => openSocialLink(socialTikTok)}
            className="hover:cursor-pointer"
          >
            <SVG src={TiktokIcon} width={25} className="mr-5" />
          </div>
          <div
            onClick={() => openSocialLink(socialYoutube)}
            className="hover:cursor-pointer"
          >
            <SVG src={YoutubeIcon} width={25} className="mr-5" />
          </div>
        </div>
      </div>
      {/* Copyright */}
      <div className="my-8">
        <p className="text-sm text-center text-gray-400">
          Copyright © {date} IVYWILD. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
