import React from "react";
import "./styles.scss";
import { GatsbyImage } from "gatsby-plugin-image";

interface MusicCardProps {
  cardImage: any;
  artist: string;
  songName: string;
}

const MusicCard: React.FC<MusicCardProps> = ({
  link,
  cardImage,
  artist,
  title,
}) => {
  return (
    <div className=" h-[363px] cursor-pointer card-shadow overflow-hidden transition-all ease-out duration-500">
      <a href={link} target="_blank">
        {/* Card Image */}
        <div className="w-full">
          <GatsbyImage
            image={cardImage}
            alt={title}
            className="w-full min-w-[320px] h-[286px]"
          />
        </div>
        <div className="h-[77px]">
          <div className="absolute p-3">
            <p className="text-base font-bold tracking-wider text-white uppercase">
              {artist}
            </p>
            <p className="text-white tracking-wider text-base font-bold text-[22px]">
              {title}
            </p>
          </div>
          <img
            src={require("../../assets/card-bg.webp").default}
            alt="card-background"
          />
        </div>
      </a>
    </div>
  );
};

export default MusicCard;
