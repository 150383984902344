import React from "react";
import MusicCard from "../music-card";

import { getImage } from "gatsby-plugin-image";

const Music: React.FC = ({ data: { musicTitle, musicList } }) => {
  return (
    <div
      className="max-w-6xl w-full mx-auto pt-16 md:pt-24 lg:pt-[150px] px-6 xl:px-0"
      id="music"
    >
      {/* Heading */}
      <div className="w-full md:pl-20">
        <h1 className="text-3xl font-bold tracking-wider text-left text-white uppercase md:text-5xl heading-left">
          {musicTitle}
        </h1>
      </div>
      <div className="grid grid-cols-1 gap-8 mt-5 sm:grid-cols-2 lg:grid-cols-3 md:mt-20">
        {musicList.map((item: any) => {
          const image = getImage(item.image);
          return (
            <div key={item.id}>
              <MusicCard
                link={item.link}
                cardImage={image}
                artist="Ivywild"
                title={item.title}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Music;
