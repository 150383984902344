import React, { useState, useRef } from "react";
import SVG from "react-inlinesvg";
import Swal from "sweetalert2";

// Assets
import SubmitBtn from "../../assets/submit-btn.svg";

const Contact: React.FC = () => {
  const formRef = useRef(null);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let formData = new FormData(formRef.current);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() =>
        Swal.fire({
          icon: "success",
          title: "Form submitted successfully!",
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        })
      )
      .catch((error) => alert(error));
  };
  return (
    <div
      className="max-w-6xl w-full mx-auto pt-16 md:pt-24 lg:pt-[100px] px-6 xl:px-0"
      id="contact"
    >
      {/* Heading */}
      <div className="w-full md:pl-20">
        <h1 className="text-3xl font-bold tracking-wider text-left text-white uppercase md:text-5xl heading-left">
          Contact
        </h1>
      </div>
      {/* Contact Form Area */}
      <div className="mt-5 md:mt-20">
        <form
          onSubmit={formSubmit}
          name="contact"
          method="POST"
          ref={formRef}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <div className="flex flex-col items-center justify-center lg:flex-row">
            {/* Form Left Side */}
            <div className="flex flex-col items-start justify-start w-4/5 lg:mr-8 lg:w-full">
              <input type="hidden" name="form-name" value="contact" />
              {/* First Name */}
              <label
                htmlFor="first_name"
                className="pb-2 text-base font-semibold text-white"
              >
                First Name*
              </label>
              <input
                type="text"
                id="first_name"
                name="firstName"
                className="w-full px-2 mb-3 h-7"
                value={form.firstName}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({ ...form, firstName: e.target.value })
                }
              />

              {/* Last Name */}
              <label
                htmlFor="last_name"
                className="pb-2 text-base font-semibold text-white"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                name="lastName"
                className="w-full px-2 mb-3 h-7"
                value={form.lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({ ...form, lastName: e.target.value })
                }
              />

              {/* Email */}
              <label
                htmlFor="email"
                className="pb-2 text-base font-semibold text-white"
              >
                Email*
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-2 mb-3 h-7"
                value={form.email}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({ ...form, email: e.target.value })
                }
              />

              {/* Phone Number */}
              <label
                htmlFor="phone_number"
                className="pb-2 text-base font-semibold text-white"
              >
                Phone Number*
              </label>
              <input
                type="number"
                id="phone_number"
                name="phoneNumber"
                className="w-full px-2 mb-3 h-7"
                value={form.phoneNumber}
                required
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setForm({ ...form, phoneNumber: e.target.value })
                }
              />
            </div>
            {/* Form Right Side */}
            <div className="flex flex-col w-4/5 lg:w-full">
              <label
                htmlFor="message"
                className="pb-2 text-base font-semibold text-white"
              >
                Message*
              </label>
              <textarea
                className="w-full h-[255px] p-2"
                id="message"
                name="message"
                value={form.message}
                required
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setForm({ ...form, message: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button type="submit" className="hidden md:block">
              <SVG src={SubmitBtn} />
            </button>
            <button
              type="submit"
              className="bg-transparent md:hidden w-4/5 md:w-2/5 h-[36px] border-[1px] border-gray-500 text-white font-semibold py-2 px-4 mt-10 leading-none"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
