import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = ({ data: { heroHeading, heroImageDesktop, heroImageMobile } }) => {
  const desktopHero = getImage(heroImageDesktop);
  const mobileHero = getImage(heroImageMobile);
  return (
    <>
      <div
        className="hidden hero md:block"
        style={{ backgroundImage: `url(${heroImageDesktop.file.url})` }}
      >
        <div className="container relative block w-full h-full max-w-6xl mx-auto md:flex">
          <div className="my-auto">
            <h1 className="z-10 w-full pl-4 text-4xl font-semibold leading-snug tracking-wide text-white uppercase xl:pl-0 lg:text-7xl md:w-1/2">
              {heroHeading}
            </h1>
          </div>
        </div>
      </div>
      <div className="relative h-full md:hidden">
        <GatsbyImage
          image={mobileHero}
          alt={heroImageDesktop.title}
          className="h-full w-full md:hidden object-cover object-center max-h-[930px]"
          loading="eager"
        />
      </div>
    </>
  );
};

export default Hero;
