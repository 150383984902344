import React from "react";

const LatestVideo: React.FC = ({
  data: { latestVideoTitle, latestVideoLink },
}) => {
  return (
    <div className="w-full max-w-6xl px-6 mx-auto xl:px-0" id="video">
      <div className="flex flex-col items-center justify-center pt-16 md:pt-24 lg:pt-[150px]">
        {/* Heading */}
        <div className="w-full md:pr-20">
          <h1 className="text-3xl font-bold tracking-wider text-right text-white uppercase md:text-5xl heading-right">
            {latestVideoTitle}
          </h1>
        </div>
        {/* Music Video (Youtube Iframe) */}
        <iframe
          src={latestVideoLink}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="w-full w-full md:w-11/12 h-40 md:h-[500px] mt-14"
        ></iframe>
      </div>
    </div>
  );
};

export default LatestVideo;
