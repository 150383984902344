import React from "react";

import SVG from "react-inlinesvg";

// Assets
import FacebookIcon from "../../assets/fb.svg";
import InstagramIcon from "../../assets/instagram.svg";
import TiktokIcon from "../../assets/tiktok.svg";
import YoutubeIcon from "../../assets/youtube.svg";

const WebIcons: React.FC = ({
  data: { socialFacebook, socialInstagram, socialTikTok, socialYoutube },
}) => {
  const openSocialLink = (link: string) => {
    if (typeof window !== "undefined") {
      window.open(link, "_newtab");
    }
  };

  return (
    <div className="items-center justify-between flex-col fixed top-0 right-0 mt-[130px] mr-20 hidden lg:flex z-10">
      <div
        onClick={() => openSocialLink(socialFacebook)}
        className="my-2 hover:cursor-pointer"
      >
        <SVG src={FacebookIcon} />
      </div>

      <div
        onClick={() => openSocialLink(socialInstagram)}
        className="my-2 hover:cursor-pointer"
      >
        <SVG src={InstagramIcon} />
      </div>
      <div
        onClick={() => openSocialLink(socialTikTok)}
        className="my-2 hover:cursor-pointer"
      >
        <SVG src={TiktokIcon} />
      </div>
      <div
        onClick={() => openSocialLink(socialYoutube)}
        className="my-2 hover:cursor-pointer"
      >
        <SVG src={YoutubeIcon} />
      </div>
    </div>
  );
};

export default WebIcons;
