import React, { useState, useEffect } from "react";
import { IoChevronUpCircleSharp } from "react-icons/io5";
import { graphql } from "gatsby";

// Components
import SEO from "../components/seo";
import Navbar from "../components/navbar";
import WebIcons from "../components/web-icons";

// Containers
import Hero from "../components/hero";
import NewRelease from "../components/new-release";
import About from "../components/about";
import LatestVideo from "../components/latest-video";
import Music from "../components/music";
import Contact from "../components/contact";
import Footer from "../components/footer";

const IndexPage: React.FC = ({ data }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const {
    metaTitle,
    metaDescription,
    heroHeading,
    heroImageDesktop,
    heroImageMobile,
    releasePosterImage,
    releaseVideo,
    aboutTitle,
    aboutDescription,
    aboutImage,
    musicTitle,
    musicList,
    latestVideoTitle,
    latestVideoLink,
    socialFacebook,
    socialInstagram,
    socialTikTok,
    socialYoutube,
  } = data.home;

  const heroSectionData = {
    heroHeading,
    heroImageDesktop,
    heroImageMobile,
  };
  const releaseSectionData = {
    releasePosterImage,
    releaseVideo,
  };
  const aboutSectionData = {
    aboutTitle,
    aboutDescription,
    aboutImage,
  };
  const musicSectionData = {
    musicTitle,
    musicList,
  };
  const videoSectionData = {
    latestVideoTitle,
    latestVideoLink,
  };
  const socialSectionData = {
    socialFacebook,
    socialInstagram,
    socialTikTok,
    socialYoutube,
  };
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="overflow-hidden">
      <SEO title={metaTitle} description={metaDescription} />
      {/* Navbar */}
      <Navbar />
      {/* Web Icons */}
      <WebIcons data={socialSectionData} />
      {/* Hero Image */}
      <Hero data={heroSectionData} />
      {/* New Release Section */}
      <NewRelease data={releaseSectionData} />
      {/* About Section */}
      <About data={aboutSectionData} />
      {/* Music Section */}
      <Music data={musicSectionData} />
      {/* Latest Video Section */}
      <LatestVideo data={videoSectionData} />
      {/* Contact */}
      <Contact />
      {/* Footer */}
      <Footer data={socialSectionData} />
      {/* Scroll To Top */}
      <div
        className={`fixed z-20 bottom-0 mb-6 right-0 mr-6 md:hidden ${
          isScrolled ? "opacity-100" : " opacity-0"
        }`}
        onClick={() => scroll(0, 0)}
      >
        <IoChevronUpCircleSharp
          fontSize={50}
          className="text-white bg-black rounded-full cursor-pointer"
        />
      </div>
    </div>
  );
};

export const query = graphql`
  query HomePageQuery {
    home: contentfulHomePage {
      metaTitle
      metaDescription
      heroHeading
      heroImageDesktop {
        id
        title
        file {
          url
        }
      }
      heroImageMobile {
        id
        title
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
      releasePosterImage {
        id
        title
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
      }
      releaseVideo
      aboutTitle
      aboutDescription {
        raw
      }
      aboutImage {
        id
        title
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
      musicTitle
      musicList {
        id
        title
        link
        image {
          id
          title
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
      latestVideoTitle
      latestVideoLink
      socialFacebook
      socialInstagram
      socialTikTok
      socialYoutube
    }
  }
`;

export default IndexPage;
