import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const About: React.FC = ({
  data: { aboutTitle, aboutDescription, aboutImage },
}) => {
  const image = getImage(aboutImage);

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        return <p className="my-4">{children}</p>;
      },
    },
  };

  return (
    <div className="max-w-6xl mx-auto pt-16 md:pt-24 lg:pt-[168px]" id="about">
      <div className="w-full max-w-screen-xl px-6 mx-auto xl:px-0">
        <div className="flex flex-col items-center justify-between md:items-start lg:flex-row">
          {/* Left Side (Image) */}
          <div className="w-full lg:w-3/5 -z-10 ">
            <GatsbyImage
              image={image}
              alt={aboutImage.title}
              className="w-full lg:w-[500px] md:h-[413px] object-contain"
            />
          </div>
          {/* Right Side (About) */}
          <div className="mx-auto mt-5 mt-12 lg:ml-24 lg:mt-0">
            {/* Heading */}
            <div className="w-full ml-auto -mt-2 text-right md:pr-20">
              <h1 className="text-3xl font-bold tracking-wider text-right text-white uppercase md:text-5xl -2 heading-right">
                {aboutTitle}
              </h1>
            </div>
            {/* Details */}
            <div className="mt-5 text-sm text-white break-words md:mt-12">
              {/* {aboutDescription.aboutDescription} */}
              {renderRichText(aboutDescription, options)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
