import { Link } from "gatsby";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./styles.scss";

interface MobileNavbarProps {
  setMobile: React.Dispatch<React.SetStateAction<boolean>>;
  navbarData: any;
}

const MobileNavbar: React.FC<MobileNavbarProps> = ({
  setMobile,
  navbarData,
}) => {
  return (
    <div className="fixed top-0 bottom-0 left-0 z-50 w-screen h-screen pt-20 bg-black slide-in">
      <img
        src={require("../../assets/card-bg.webp").default}
        alt="Background"
        className="absolute w-full h-full -mt-20 "
      />
      <div onClick={() => setMobile(false)}>
        <AiOutlineClose
          className="absolute right-0 mr-10 text-white cursor-pointer"
          fontSize={30}
        />
      </div>
      <div className="">
        <ul className="flex flex-col items-center justify-start">
          {navbarData.map((item: string, index: number) => (
            <li
              key={index}
              className="mx-10 my-14 nav-item-hover hover:translate-x-[0.1rem] hover:transition-all hover:ease-out hover:duration-[0.2s] "
            >
              <Link
                to={`#${item.link}`}
                onClick={() => setMobile(false)}
                className="text-white text-2xl uppercase font-bold hover:text-[#ED1E79]
                      transition-all ease-out duration-[0.2s] "
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileNavbar;
